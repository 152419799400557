import { LoginContainer } from 'modules/auth/components/LoginContainer/LoginContainer'
import { EnumSignupType } from 'modules/auth/types/signup'
import { Seo } from 'modules/common/components/Seo/Seo'
import LayoutLogin from 'modules/common/components/_layouts/LayoutLogin'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { BlankPage } from 'modules/common/types/page'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const LoginPage: BlankPage = () => {
  const { t } = useTranslation('auth-page-login')

  return (
    <>
      <Seo
        title={t('meta.title')}
        description={t('meta.description')}
        noindex
      />
      <LoginContainer signupType={EnumSignupType.BANK} />
    </>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale as string, [
      'auth-page-login',
      'auth-components',
      'common',
      'common-components',
    ])),
  },
})

LoginPage.getLayout = (page) => <LayoutLogin>{page}</LayoutLogin>
LoginPage.type = 'notAuthenticated'

export default LoginPage
